* {
  box-sizing: border-box;
  --clr-black: black;
  --clr-green: #36b188;
  --box-shadow: #959da533 0px 8px 24px;
  --drop-shadow: drop-shadow(0 0 .75rem #c8c8c8);
  --clr-lightgrey: #f7f6f6;
  margin: 0;
  padding: 0;
}

html {
  font-family: Comfortaa, cursive;
  font-size: 62.5%;
}

h1, h2, h3, p {
  color: var(--clr-black);
}

h1, h2, h3 {
  font-weight: 700;
}

h1 {
  font-size: 4rem;
}

h1 span, h2 span {
  color: var(--clr-green);
}

h2, h3 {
  font-size: 3.5rem;
}

h4 {
  color: var(--clr-green);
  font-size: 2.2rem;
}

p {
  color: #4e4e4e;
  margin-bottom: 2rem;
}

p, a {
  line-height: 30px;
}

p, p span, a, button, input, div {
  font-size: 1.9rem;
}

a {
  color: var(--clr-black);
  border: none;
  border-bottom: 1px solid gray;
  padding: .2rem;
  text-decoration: none;
}

button {
  color: var(--clr-black);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.primary-button {
  border: 1px solid var(--clr-green);
  border-radius: 20px;
  padding: 1rem 3rem;
  font-weight: 700;
}

.primary-button:hover {
  background-color: var(--clr-lightgrey);
}

input {
  border: none;
  border: 1px solid var(--clr-lightgrey);
  background: none;
  border-radius: 20px;
  outline: none;
  padding: 1rem 3rem;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: var(--clr-lightgrey);
}

::-webkit-scrollbar-thumb {
  background: #d7d7d7;
}

::-webkit-scrollbar-thumb:hover {
  background: #d7d7d7;
}

::-webkit-scrollbar-corner {
  background-color: var(--clr-lightgrey);
}

.primary-nav:before {
  content: "";
  width: 100%;
  height: 8px;
  background: linear-gradient(to left, #36b188, #40e0d0);
  position: absolute;
  top: -5px;
  left: 0;
}

.primary-nav {
  width: 100%;
  z-index: 4;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
}

.primary-nav a {
  border-bottom: none;
}

.primary-nav div {
  max-width: 170rem;
  border-bottom: 1px solid var(--clr-lightgrey);
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 1rem;
  display: flex;
}

.primary-nav div img {
  max-width: 20rem;
}

.primary-nav div button {
  border-radius: 5px;
  padding: .5rem;
  font-size: 3rem;
}

.primary-nav div button:hover {
  background-color: var(--clr-lightgrey);
}

.home-header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12rem;
  padding: 0 1rem;
  display: flex;
}

.home-header h1 {
  margin-bottom: 4rem;
}

.search-container {
  max-width: 80rem;
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  padding: 3rem;
}

.search-container form {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.search-container input {
  margin-right: 2rem;
}

.dropdown-container {
  z-index: 3;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  display: flex;
  position: relative;
}

.dropdown-container button {
  font-size: 1.4rem;
}

.dropdown-btn {
  color: gray;
}

.dropdown-btn svg {
  margin-left: .5rem;
}

.dropdown-btn:hover, .dropdown-btn--active {
  color: var(--clr-green);
}

.dropdown-options {
  background-color: var(--clr-lightgrey);
  max-height: 20rem;
  max-width: 20rem;
  border: 10px solid var(--clr-lightgrey);
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow-y: auto;
  transform: translateY(12rem);
}

.dropdown-options button {
  padding: 1rem;
}

.dropdown-options button:hover {
  color: var(--clr-green);
}

.seperator {
  height: 7rem;
  width: 1.5px;
  background-color: var(--clr-black);
  margin: 2rem 0;
}

.country-content-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  display: flex;
}

.country-content-container h2 {
  text-align: center;
}

.country-content-container .general-data {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  display: flex;
}

.country-content-container .general-data h3 {
  text-align: center;
}

.country-content-container .imgs-flag--coat {
  justify-content: center;
  align-items: center;
  display: flex;
}

.country-content-container .imgs-flag--coat img {
  max-width: 20rem;
  filter: var(--drop-shadow);
  margin: 0 1rem;
}

.country-content-container .other-data-grid {
  max-width: 120rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin: 3rem 0;
  padding: 0 1rem;
  display: grid;
}

.country-content-container .other-data-grid .item {
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.country-content-container .other-data-grid .item img {
  max-width: 7rem;
  margin-right: 1.5rem;
}

.country-content-container .other-data-grid .item h4 {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 1rem;
  padding-bottom: .5rem;
}

.country-content-container .country-main-img {
  max-width: 100rem;
  max-height: 80rem;
  position: relative;
}

.country-content-container .country-main-img .author {
  background-color: #ffffffa8;
  border-radius: 0 20px 0 0;
  padding: 0 .5rem;
}

.country-content-container .country-main-img img {
  width: 100%;
  max-height: 80rem;
  object-fit: cover;
  vertical-align: middle;
  filter: var(--drop-shadow);
  border-radius: 20px;
}

.country-content-container .country-main-img p, .country-content-container .country-main-img a {
  color: var(--clr-black);
  border-bottom: none;
  font-size: 1rem;
  font-weight: 700;
}

.country-content-container .country-description {
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  padding: 0 1rem;
  display: flex;
}

.country-content-container .country-description img {
  max-width: 10rem;
  filter: var(--drop-shadow);
  margin-right: 3rem;
}

.country-content-container .internal-wrapper {
  max-width: 90rem;
  box-shadow: var(--box-shadow);
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.country-content-container .slider-wrapper {
  height: 90vh;
  width: 80vw;
  position: relative;
  overflow: hidden;
}

.country-content-container .slider-wrapper .slide {
  height: 100%;
  width: 100%;
  transition: transform 1s;
  position: absolute;
}

.country-content-container .slider-wrapper .slide p {
  background-color: #ffffffa8;
  padding: 0 .2rem;
}

.country-content-container .slider-wrapper .slide p, .country-content-container .slider-wrapper .slide a {
  color: var(--clr-black);
  border-bottom: none;
  font-size: 1rem;
}

.country-content-container .slider-wrapper .slide img {
  filter: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.country-content-container .slider-wrapper .slider-btn {
  z-index: 3;
  background-color: var(--clr-green);
  color: #fff;
  border-radius: 50%;
  padding: 1rem 1.2rem;
  font-size: 3rem;
  position: absolute;
  top: 50%;
}

.country-content-container .slider-wrapper .slider-btn:hover {
  background-color: #0dac77;
}

.country-content-container .slider-wrapper .slide-left-btn {
  left: 5%;
}

.country-content-container .slider-wrapper .slide-right-btn {
  right: 5%;
}

.country-content-container .links-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.country-content-container .links-container h4 {
  margin-bottom: 2rem;
}

.country-content-container .links-container a {
  white-space: pre-wrap;
  word-break: break-all;
  margin: .5rem;
}

.links {
  max-width: 90rem;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.author {
  z-index: 3;
  color: #fff;
  margin-bottom: 0;
  position: absolute;
  top: 0%;
  right: 0%;
}

.thank-you-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10rem 0;
  display: flex;
}

.thank-you-section a {
  border-bottom: none;
}

.thank-you-section .partners {
  margin: 3rem 0;
}

.thank-you-section .partners img {
  max-width: 13rem;
  filter: var(--drop-shadow);
  margin: 0 1rem;
}

.thank-you-section picture {
  width: 100%;
  position: relative;
}

.thank-you-section picture img {
  width: 100%;
  max-height: 50rem;
  object-fit: cover;
  filter: var(--drop-shadow);
}

.thank-you-section picture p, .thank-you-section picture a {
  color: #fff;
  font-size: 1rem;
}

.primary-footer {
  text-align: center;
}

#map {
  height: 60vh;
  width: 80vw;
  z-index: 2;
  border-radius: 20px;
  margin: 3rem 0;
}

.leaflet-popup-content {
  min-width: max-content;
}

.square-unit {
  font-size: 1rem;
  position: absolute;
  transform: translateY(-.5rem);
}

.download-btn {
  color: var(--clr-green);
  z-index: 4;
  z-index: 1;
  border-bottom: none;
  font-size: 2rem;
  position: absolute;
  top: 2%;
  left: 3%;
}

.download-btn:hover {
  color: #179f72;
}

.country-not-found-label {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
}

.error-label-fixed {
  z-index: 3;
  background-color: var(--clr-green);
  color: #fff;
  width: 100%;
  margin: 0;
  padding: .5rem;
  font-size: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
}

.hide {
  display: none;
}

@media (max-width: 1135.98px) {
  .country-content-container .slider-wrapper {
    width: 100%;
  }
}

@media (max-width: 1000.98px) {
  .country-content-container .slider-wrapper {
    height: 50vh;
  }
}

@media (max-width: 790.98px) {
  h1 {
    font-size: 3.5rem;
  }

  h2, h3 {
    font-size: 2.8rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  p, a, button, input, div {
    font-size: 1.6rem;
  }

  .primary-nav div img {
    max-width: 15rem;
  }

  .primary-nav div button {
    font-size: 2.5rem;
  }

  #map {
    width: 95vw;
    height: 40vh;
  }

  .leaflet-popup-content {
    min-width: -moz-fit-content;
    min-width: fit-content;
    text-align: center;
    font-size: 1.3rem;
  }

  .country-content-container {
    margin-top: 2rem;
  }

  .country-content-container .imgs-flag--coat img {
    max-width: 15rem;
  }

  .country-content-container .other-data-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .country-content-container .slider-wrapper .slider-btn {
    font-size: 2rem;
  }

  .thank-you-section .partners img {
    max-width: 7rem;
  }
}

@media (max-width: 539.98px) {
  h1 {
    text-align: center;
    font-size: 3rem;
  }

  .search-container input {
    width: 100%;
    margin-right: 0;
  }

  .search-container form {
    flex-wrap: wrap;
  }

  .search-container form > div {
    margin-left: auto;
  }

  .search-container button {
    margin-top: 1rem;
  }

  .country-content-container .internal-wrapper {
    flex-wrap: wrap;
  }

  .country-content-container .country-description img {
    margin-bottom: 2rem;
    margin-right: 0;
  }

  .country-content-container .slider-wrapper {
    height: 35vh;
  }
}

@media (max-width: 417.98px) {
  h1 {
    font-size: 2.7rem;
  }

  h2, h3 {
    font-size: 2.3rem;
  }

  h4 {
    font-size: 1.7rem;
  }

  .country-content-container .imgs-flag--coat img {
    max-width: 10rem;
  }

  .country-content-container .other-data-grid .item img {
    max-width: 5rem;
  }

  .thank-you-section .partners {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .thank-you-section .partners img {
    margin: .5rem;
  }
}

/*# sourceMappingURL=index.432b685b.css.map */
